var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"dashboard","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","lg":"3"}},[_c('DateRangePicker',{attrs:{"def-dates":_vm.dates},on:{"update":_vm.onDateRangeUpdated}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","lg":"3"}},[_c('ScopeSelector',{attrs:{"vertical":"","preselected-merchant-id":_vm.$route.query.merchantId},on:{"org-selected":_vm.onOrganizationSelected,"mch-selected":_vm.onMerchantSelected}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","lg":"3"}},[_c('v-btn',{attrs:{"block":"","large":"","color":"purple darken-3"},on:{"click":_vm.exportDiscountRecords}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-export")]),_vm._v(" Download as Excel ")],1),_c('v-btn',{attrs:{"block":"","large":"","text":"","color":"grey darken-3"},on:{"click":_vm.forceRefresh}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mt-0"},[_c('v-card-text',[_c('SSRTable',{ref:"list-records",attrs:{"data-url":_vm.loadUrl,"data-key":'records',"schema":_vm.schema},scopedSlots:_vm._u([{key:"merchant_name",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('span',[_vm._v(_vm._s(item.merchant_name))]),_c('br'),(item.counter_id)?_c('span',{staticClass:"text-body-2"},[_c('strong',[_vm._v("Counter")]),_vm._v(" "+_vm._s(item.counter_name))]):_vm._e()])}},{key:"discount",fn:function(ref){
var item = ref.item;
return _c('div',{},[_vm._v(" $"+_vm._s(Number(item.discount).toLocaleString())+" / $"+_vm._s(Number(item.amount).toLocaleString())+" ")])}},{key:"payer",fn:function(ref){
var item = ref.item;
return _c('div',{},[_vm._v(" "+_vm._s(item.payer_name)),_c('br'),_vm._v(" "+_vm._s(item.payer_phone)+" ")])}},{key:"payment_order_id",fn:function(ref){
var item = ref.item;
return _c('div',{},[(item.payment_order_id)?_c('span',{staticClass:"clickable",on:{"click":function($event){return _vm.showOrderInfoDialog(item)}}},[_vm._v(" "+_vm._s(item.payment_order_id)+" ")]):_vm._e(),_c('br'),(item.payment_order_id)?_c('span',[_vm._v(_vm._s(item.paid_at))]):_vm._e()])}}])})],1)],1)],1)],1),_c('OrderDetailDialog',{attrs:{"show":_vm.dialog.orderInfo.show,"payment-order-id":_vm.dialog.orderInfo.paymentOrderId},on:{"update:show":function($event){return _vm.$set(_vm.dialog.orderInfo, "show", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }