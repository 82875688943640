<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <!-- QUERY SETTINGS -->
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="4" lg="3">
            <DateRangePicker @update="onDateRangeUpdated"
              :def-dates="dates">
            </DateRangePicker>
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <ScopeSelector vertical
              @org-selected="onOrganizationSelected"
              @mch-selected="onMerchantSelected"
              :preselected-merchant-id="$route.query.merchantId">
            </ScopeSelector>
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <v-btn block large color="purple darken-3" @click="exportDiscountRecords">
              <v-icon left>mdi-file-export</v-icon>
              Download as Excel
            </v-btn>
            <v-btn block large text color="grey darken-3" @click="forceRefresh">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <!-- LIST OF DISCOUNT RECORDS -->
      <v-col cols="12">
        <v-card class="mt-0">
          <v-card-text>
            <SSRTable ref="list-records"
              :data-url="loadUrl" :data-key="'records'" :schema="schema">

              <div slot="merchant_name" slot-scope="{item}">
                <span>{{ item.merchant_name }}</span><br>
                <span class="text-body-2" v-if="item.counter_id"><strong>Counter</strong>&nbsp;{{ item.counter_name }}</span>
              </div>

              <div slot="discount" slot-scope="{item}">
                ${{ Number(item.discount).toLocaleString() }} / ${{ Number(item.amount).toLocaleString() }}
              </div>

              <div slot="payer" slot-scope="{item}">
                {{ item.payer_name }}<br>
                {{ item.payer_phone }}
              </div>

              <!-- <div slot="status" slot-scope="{item}">
                <div>
                  <v-chip small outlined color="green darken-1" v-if="item.status === 'completed'">
                    {{ $t('views.vasDonations.paid') }}<v-icon right>mdi-check</v-icon>
                  </v-chip>
                  <v-chip small outlined color="amber darken-3" v-else-if="item.status === 'refund'">
                    {{ $t('views.vasDonations.refunded') }}<v-icon right>mdi-cancel</v-icon>
                  </v-chip>
                  <v-chip small outlined color="grey accent-3" v-else>
                    {{ $t('views.vasDonations.incomplete') }}<v-icon right>mdi-progress-clock</v-icon>
                  </v-chip>
                </div>
              </div> -->


              <div slot="payment_order_id" slot-scope="{item}">
                <span class="clickable" v-if="item.payment_order_id"
                  @click="showOrderInfoDialog(item)">
                  {{ item.payment_order_id }}
                </span><br>
                <span v-if="item.payment_order_id">{{ item.paid_at }}</span>
              </div>
            </SSRTable>

          </v-card-text>
        </v-card>
      </v-col>

    </v-row>


    <!-- Dialog for showing detail and transactional history of the selected order -->
    <OrderDetailDialog :show.sync="dialog.orderInfo.show"
      :payment-order-id="dialog.orderInfo.paymentOrderId">
    </OrderDetailDialog>

  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable';
import DateRangePicker from '@/components/DateRangePicker';
import ScopeSelector from '@/components/ScopeSelector';

import OrderDetailDialog from '@/views/dashboard/subviews/OrderDetailDialog';

import axios from 'axios';
import moment from 'moment';

export default {
  components: {
    SSRTable,
    DateRangePicker,
    ScopeSelector,
    OrderDetailDialog
  },

  mounted() {
    //NOTE: If a merchant ID is given in URL query, update scope filter immediately.
    if (this.$route.query.merchantId) {
      this.scope.merchant = { 'merchant_id': this.$route.query.merchantId };
    }
  },

  methods: {
    onDateRangeUpdated(dates) {
      this.dates = dates;
    },
    onOrganizationSelected(org) {
      this.scope.organization = org;
    },
    onMerchantSelected(merchant) {
      this.scope.merchant = merchant;
    },
    forceRefresh() {
      this.$refs['list-records'].loadData();
    },
    
    showOrderInfoDialog(record) {
      this.dialog.orderInfo.paymentOrderId = record['payment_order_id'];
      this.dialog.orderInfo.show = true;
    },

    exportDiscountRecords() {
      let from = moment(this.dates[0]).startOf('day');
      let to = moment(this.dates[1]).endOf('day');
      window.open(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/vas/member-discount/export`
        + `?from=${from.unix()}&to=${to.unix()}`)
        + (this.scope.organization? `&orgId=${this.scope.organization['id']}`: '')
        + (this.scope.merchant? `&merchantId=${this.scope.merchant['merchant_id']}`: '');
    }
  },

  data() {
    return {
      dates: [
        moment().startOf('day').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ],
      scope: {
        organization: null,
        merchant: null
      },

      dialog: {
        orderInfo: {
          show: false,
          paymentOrderId: ''
        }
      }
    };
  },

  computed: {
    loadUrl() {
      let from = moment(this.dates[0]).startOf('day');
      let to = moment(this.dates[1]).endOf('day');
      return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/vas/member-discount/records?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`
        + `&from=${from.unix()}&to=${to.unix()}`
        + (this.scope.organization? `&orgId=${this.scope.organization['id']}`: '')
        + (this.scope.merchant? `&merchantId=${this.scope.merchant['merchant_id']}`: '');
    },
    schema() {
      return [
        { value: 'merchant_name', text: this.$t('views.vasMemberDiscount.merchantName') },
        { value: 'created_at', text: this.$t('views.vasMemberDiscount.createdAt') },
        { value: 'discount', text: this.$t('views.vasMemberDiscount.discountVsPaid') },
        { value: 'payer', text: this.$t('views.vasMemberDiscount.payer'), align: 'end' },
        { value: 'payment_order_id', text: this.$t('views.vasMemberDiscount.paymentOrderId') },
        // { value: 'actions', text: '' }
      ];
    },

    user() {
      return this.$store.getters.user;
    }
  }
}
</script>

<style scoped>
span.clickable {
  cursor: pointer;
  color: darkcyan;
}
</style>